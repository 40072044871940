define("discourse/plugins/discourse-adplugin/discourse/routes/admin-plugins-house-ads", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _object, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsHouseAds extends _discourse.default {
    settings = null;
    model() {
      return (0, _ajax.ajax)("/admin/plugins/pluginad/house_creatives.json").then(data => {
        this.set("settings", _object.default.create(data.settings));
        return data.house_ads.map(ad => _object.default.create(ad));
      });
    }
    setupController(controller, model) {
      controller.setProperties({
        model,
        houseAdsSettings: this.get("settings"),
        loadingAds: false
      });
    }
  }
  _exports.default = AdminPluginsHouseAds;
});