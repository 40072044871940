define("discourse/plugins/discourse-adplugin/discourse/controllers/admin-plugins-house-ads", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsHouseAdsController extends _controller.default {
    loadingAds = true;
  }
  _exports.default = AdminPluginsHouseAdsController;
});