define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-list-setting", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember-decorators/component", "discourse/lib/helpers", "discourse/plugins/discourse-adplugin/discourse/components/house-ads-setting", "@ember/template-factory"], function (_exports, _component, _object, _computed, _component2, _helpers, _houseAdsSetting, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label for={{this.name}}>{{this.title}}</label>
  {{house-ads-chooser
    settingValue=this.adValue
    choices=this.adNames
    onChange=this.changeAdValue
  }}
  <div class="setting-controls">
    {{#if this.changed}}
      <DButton class="ok" @action={{action "save"}} @icon="check" />
      <DButton class="cancel" @action={{action "cancel"}} @icon="xmark" />
    {{/if}}
  </div>
  <p class="help">{{this.help}}</p>
  */
  {
    "id": "yJGCRxya",
    "block": "[[[10,\"label\"],[15,\"for\",[30,0,[\"name\"]]],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n\"],[1,[28,[35,1],null,[[\"settingValue\",\"choices\",\"onChange\"],[[30,0,[\"adValue\"]],[30,0,[\"adNames\"]],[30,0,[\"changeAdValue\"]]]]]],[1,\"\\n\"],[10,0],[14,0,\"setting-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"changed\"]],[[[1,\"    \"],[8,[39,4],[[24,0,\"ok\"]],[[\"@action\",\"@icon\"],[[28,[37,5],[[30,0],\"save\"],null],\"check\"]],null],[1,\"\\n    \"],[8,[39,4],[[24,0,\"cancel\"]],[[\"@action\",\"@icon\"],[[28,[37,5],[[30,0],\"cancel\"],null],\"xmark\"]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,2],[14,0,\"help\"],[12],[1,[30,0,[\"help\"]]],[13]],[],false,[\"label\",\"house-ads-chooser\",\"div\",\"if\",\"d-button\",\"action\",\"p\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/house-ads-list-setting.hbs",
    "isStrictMode": false
  });
  const HouseAdsListSetting = dt7948.c(class HouseAdsListSetting extends _houseAdsSetting.default {
    static #_ = (() => dt7948.g(this.prototype, "adNames", [(0, _computed.mapBy)("allAds", "name")]))();
    #adNames = (() => (dt7948.i(this, "adNames"), void 0))();
    changeAdValue(value) {
      const settingValue = (0, _helpers.makeArray)(value).join("|");
      this.set("adValue", settingValue);
    }
    static #_2 = (() => dt7948.n(this.prototype, "changeAdValue", [_object.action]))();
  }, [(0, _component2.classNames)("house-ads-setting house-ads-list-setting")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HouseAdsListSetting);
});