define("discourse/plugins/discourse-adplugin/discourse/routes/admin-plugins-house-ads-index", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse"], function (_exports, _object, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsHouseAdsIndex extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    moreSettings() {
      this.router.transitionTo("adminSiteSettingsCategory", "ad_plugin");
    }
    static #_2 = (() => dt7948.n(this.prototype, "moreSettings", [_object.action]))();
  }
  _exports.default = AdminPluginsHouseAdsIndex;
});